.custom-file-uploader {
    border: none;
    width: 100%;
    max-width: 224px;
    padding: 7px 0;
    text-align: center;
    border-radius: 5px;
    display: block;
    cursor: pointer;
    background-color: #E9E9E9;

    .custom-file-uploader-input {
        display: none;
    }
}