.boxes {
  display: flex;
  gap: 0rem 33px;
}

@media screen and (max-width: 1000px) {
  #home .boxes {
    width: 100%;
    gap: 12px;
    flex-wrap: wrap;
    margin-bottom: 32px;

    a {
      width: calc(33% - 12px);
    }

    .box {
      min-width: none;
      max-width: none !important;
      max-height: none;
      padding: 1rem;
      text-align: center;
      margin-bottom: 0;
      width: 100%;

      .box-text {
        margin-left: 0;
      }
    }
  }

  .data-table .header h2 {
    margin-bottom: 1rem;
  }
}
