$side-mh: 55rem;

.navbar {
  position: sticky;
  top: 0;
  left: 0;
  max-width: 8.4rem;
  min-height: 100vh;
  max-height: $side-mh;

  background: var(--global-green);

  border-right: 1px solid var(--gray-border);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  padding: 3.3rem 3.2rem 7.5rem 3.2rem;

  img {
    width: 4rem;
  }

  svg{
    font-size: 28px;
    color: var(--green);
  }

  a {
    text-decoration: none;
    color: blue;
  }

  .logout-btn {
    opacity: .5;
    transition: .5;
    cursor: pointer;
  }
  .logout-btn:hover {
    opacity: 1;
  }

  ul {
    height: 100%;
    max-height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    list-style-type: none;

    margin-top: 100px;

    li {
      .active {
        position: relative;
      }

      .active::after {
        content: ('');
        width: 4px;
        height: 37px;
        border-radius: 5px 0px 0px 5px;
        background: var(--green);
        position: absolute;
        top: -18px;
        right: -38px;
      }

      text-align: center;

      img {
        width: 2rem;
      }

      .logout-btn{
        svg{
          font-size: 28px;
          color: var(--green)
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  #nav-bar_main-img {
    display: none;
  }

  .navbar {
    margin-top: auto;
    position: sticky;
    bottom: 0;
    max-width: none;
    max-height: 75px;
    min-height: min-content;
    width: 100vw;
    z-index: 100;

    padding: 0 2rem;
  }

  ul {
    height: fit-content!important;
    flex-direction: row !important;
    width: 100%;
    justify-content: space-around;

    margin-top: 0 !important;
    padding: 12px 0px;

    li {
      .active::after {
        right: -.5rem !important;
        top: -2.8rem !important;
        height: 4px !important;
        width: 37px !important;
        border-radius: 0px 0px 5px 5px !important;
      }
    }
  }
}