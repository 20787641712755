.custom-tooltip-container {
  position: absolute;
  bottom: 7rem;
  padding: 3rem;
  gap: 30px;
  right: -70px;
  border-radius: 5px;
  background: var(--global-green);
  border: 1px solid var(--border-color);

  &.active-tooltip {
    display: flex;
  }
  &.inactive-tooltip {
    display: none;
  }

  .custom-tooltip-element {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.bubble:after {
  content: "";
  content: "";
  position: absolute;
  display: block;
  width: 0;
  z-index: 1;
  border-style: solid;
  border-color: var(--global-green) transparent;
  border-width: 20px 20px 0;
  bottom: -20px;
  left: 65%;
  margin-left: -20px;
}
