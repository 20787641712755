*,
*::after,
*::before {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  font-size: 12px;
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
}

/*displays*/

.flex {
  display: flex;
}

.flex.wrap {
  flex-wrap: wrap;
}

.flex.column {
  flex-direction: column;
}

.flex.column.reverse {
  flex-direction: column-reverse;
}

.flex.row {
  flex-direction: row;
}

.flex.row.reverse {
  flex-direction: row-reverse;
}

.flex.jc-c {
  justify-content: center;
}

.flex-jc-fs {
  justify-content: flex-start;
}

.flex-jc-fe {
  justify-content: flex-end;
}

.flex.jc-sb {
  justify-content: space-between;
}

.flex.jc-se {
  justify-content: space-evenly;
}

.flex.jc-sa {
  justify-content: space-around;
}

.flex.ai-c {
  align-items: center;
}

.flex.ai-fs {
  align-items: flex-start;
}

.flex.ai-fe {
  align-items: flex-end;
}

/*Widts*/
.w100p {
  width: 100%;
}

@media (min-width: 1201px) {
  .desktop-w50p {
    width: 50%;
  }
}

@media (max-width: 1200px) {
  .mobile-column {
    flex-direction: column;
  }

  .mobile-column-reverse {
    flex-direction: column-reverse;
  }

  .desktop-w50p {
    width: 100%;
  }
}

/*Border Radius*/

.br-5 {
  border-radius: 5px;
}

.br-40 {
  border-radius: 40px;
}

/*---------------*/
.button,
button {
  border: none;
  width: 100%;
  max-width: 224px;
  padding: 7px 0;
  text-align: center;
  border-radius: 5px;
  display: block;
  cursor: pointer;
}

.button.btn-center {
  margin: auto;
}

.no-display {
  display: none;
}

.p-relative {
  position: relative;
}

@media (min-width: 1001px) {
  .desktop-only {
    display: block;
  }

  .mobile-only {
    display: none;
  }
}

@media (max-width: 1200px) {
  .desktop-only {
    display: none;
  }

  .mobile-only {
    display: flex;
  }

  .left,
  .right {
    width: 100% !important;
    max-width: none !important;
  }

  .button {
    font-size: 16px;
  }

  .wrapper {
    width: 90% !important;
  }
}

// New General Styles
.atlantic-checkbox {
  accent-color: var(--green);
}

div:has(.checkbox) {
  .checkbox {
    width: 20px;
    height: 20px;
    border: 1px solid grey;
    border-radius: 5px;
    cursor: pointer;

    .checked-item {
      color: var(--green);
      font-weight: 600;
      position: relative;
      left: 3px;
      font-size: 15px;
    }
  }
}
