#grey-line {
  border-top: 1px solid #E9E9E9;
  width: calc(100vw - 8.4rem);
}

#responsive-logo {
  display: none;

  width: 4rem;
}

.cont .left {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.cont{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3rem 0rem 2.6rem 2.4rem;
}

#new-user{
  padding: 7px 33px;
  background-color: var(--green);
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 12px;
}

@media screen and (max-width: 1000px) {
  #responsive-logo {
    display: block !important;
  }

  .cont{
    justify-content: flex-start;
    gap: 0px 24px;
    padding: 1rem 0;
  }

  #grey-line {
    max-width: 100vw!important;
    width: 100%;
    margin-bottom: 0;
  }
  .left{
    gap: 0px 12px;
  }
}

.page-title.loading {
  h3 {
    background: linear-gradient(-45deg, #efefef, #ffffff, #efefef, #ffffff);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
    border-radius: 5px;
    min-width: 20rem;

    color: transparent;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}