#billing {
  input {
    border: none;
  }

  input:focus {
    outline: none;
  }

  .page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    gap: 22px;
    margin-bottom: 28.5px;

    .input {
      display: flex;
      justify-content: space-between;
      border: 1px solid var(--border-color);
      padding: 8px 14px;
      width: 65%;

      border-radius: 5px;

      svg {
        color: #303030;
      }
    }

    .filters {
      width: 35%;
      display: flex;
      justify-content: space-between;
    }
  }

  table {
    text-align: center;

    p,
    .id {
      text-align: center;
    }

    td:first-child p {
      text-align: left;
    }

    .id {
      cursor: pointer;
    }
  }
}

#invoice {
  display: flex;

  .button.danger {
    max-width: none;
  }

  p {
    color: #303030;
  }

  .left-side {
    width: 75%;

    section {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    #section-1 {
      background: var(--global-green);
      padding: 3.6rem 3.3rem 4.7rem 3.3rem;

      img {
        width: 4rem;
        margin-bottom: 1.16rem;
      }

      .atlantic-info {
        max-width: 13rem;

        .main-title {
          font-size: 1.5rem;
          font-weight: bold;
          line-height: 1.83rem;
        }

        .basic-text {
          line-height: 1.7rem;
          font-size: 1.16rem;
        }
      }

      .billing-data {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: calc(23.3rem - (3.6rem + 4.7rem));

        .billing-info {
          text-align: right;
        }

        .billing-balance {
          font-size: 2.83rem;
          font-weight: bold;
        }
      }
    }

    #section-2 {
      padding: 2.83rem 3.42rem 6.83rem 3.7rem;

      .section-body {
        .basic-text {
          font-size: 1.3rem;
        }

        #bill-to {
          margin-bottom: 1.06rem;
          line-height: 1.7rem;
        }

        #info {}
      }
    }

    #section-3 {
      padding: 0 3.42rem 6.83rem 3.7rem;
      display: block;

      .basic-text {
        font-size: 1.16rem;
        margin-bottom: 1.06rem;
      }

      .customs {
        display: flex;
        justify-content: space-between;
        border-radius: 5px;
        border: 1px solid var(--border-color);
        padding: 2rem;
        line-height: 1.7rem;
      }

      .receipt {
        margin-top: 3rem;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        max-width: 55%;
        margin-left: auto;

        .subtotal,
        .total {
          display: flex;
          justify-content: space-between;
          width: 100%;
          font-size: 1.16rem;
        }

        .subtotal {}

        .grey-line {
          width: 100%;
          height: 1px;
          background: var(--border-color);
          margin: 10px 0;
        }

        .total {
          font-weight: bold;
        }
      }
    }

  }


  .upload-backup-field {
    position: relative;
    cursor: pointer;

    input[type="file"] {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      opacity: 0;
      cursor: pointer;
    }

    label {
      gap: 0 16px;
      width: 100%;
      height: 35px;
      margin-bottom: 16px;
      background-color: var(--global-green);
      border: 1px solid var(--border-color);
      cursor: pointer;

      .text {
        color: black;
      }
    }
  }

  .file-visualizer {
    max-width: 101px;
    width: 100%;
    height: 105px;
    padding: 18px 21px;
    gap: 16px 0;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid var(--border-color);
    position: relative;
    margin-bottom: 1rem;

    #close-btn {
      position: absolute;
      background-color: #dddada;
      border-radius: 50%;

      top: -8px;
      right: -8px;

      width: 1.75rem;
      height: 1.75rem;
    }

    a {
      width: 100%;
      font-size: 12px;
      font-weight: 500;
      color: #6e6e6e;
      text-align: center;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    svg {
      path {
        fill: #9b9b9b;
      }
    }
  }

  .file-visualizer.available {
    border: 1px solid var(--green);
    background-color: var(--global-green);
    text-align: center;

    p {
      color: var(--green);
    }

    svg path {
      fill: var(--green);
    }
  }

  .right-side {
    width: 25%;
    margin-left: 34px;
    min-width: 29rem;

    section {
      border: 1px solid var(--border-color);
      display: flex;
      flex-wrap: wrap;
      border-radius: 5px;
      margin-bottom: 15px;

      .bold {
        font-weight: bold;
        font-size: 2rem;
      }
    }

    section:hover {
      border: 1px solid var(--green);
      background: var(--global-green);
      transition: all .25s;
      cursor: pointer;
    }

    .pay-info {
      padding: 1.08rem 2.75rem 1.912rem 1.912rem;

      .title {
        font-size: 1.16rem;
      }

      .pay-links {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
    }

    #section-1 {
      justify-content: space-between;
      padding: 2.6rem 2rem 1.83rem 2rem;
    }

    #section-2,
    #section-4 {
      .title {
        margin-bottom: 29px;
      }
    }

    #section-3,
    #section-5 {
      .title {
        margin-bottom: 19px;
      }
    }

    #section-6 {
      background: var(--global-green);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1rem 0;

      svg {
        font-size: 1.5rem;
        color: var(--green);
      }

      p {
        font-size: 1rem;
        margin-left: 1.3rem;
      }
    }
  }
}

.invoice-file-number {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

@media screen and (max-width: 1000px) {
  .payment-boxes {
    gap: 1rem 0;

    .payment-box {
      width: 90%;
      margin: auto;
      min-height: none;
      text-align: center;
      align-items: center;
      justify-content: center;
    }

    #box-1 {
      text-align: center;
      min-width: none !important;

      .payment-body {
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

#invoice {
  padding-bottom: 0;
  height: calc(100% - 13rem);

  @media screen and (max-width: 1000px) {
    flex-direction: column-reverse;

    .right-side {
      padding: 0 1rem;
      width: 100%;
      margin-left: 0;
    }

    .left-side {
      padding: 0 1rem;
      width: 100%;
      min-height: 60rem;
    }
  }
}

@media screen and (max-width: 1000px) {}