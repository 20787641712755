.search-bar {
  border: 1px solid var(--gray-border);
  color: #303030;
  font-size: 1rem;
  padding: 0.75rem 0 0.75rem 1.16rem;
  border-radius: 5px;
  min-width: 50vw;
  margin-bottom: 2rem;
}

.data-table a p {
  transition: .25s;
}

.data-table a p:hover {
  color: var(--green);
}

.custom-loading-animation-swal-popup {
  background-color: transparent !important;
}

.data-table {
  .search-input {
    display: flex;
    align-items: center;
    width: 100%;
    border: 1px solid var(--border-color);
    border-radius: 5px;

    input:focus {
      border: none;
      outline: none;
    }

    input {
      border: none;
      padding: 8px 12px;
      width: 95%;
    }

  }
}

select {
  max-width: 126px !important;
}

.select_shipment {
  width: 10px !important;

  padding: 0 !important;
}