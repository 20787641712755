$--container-width: 69%;
$--gray: #707070;

#settings {
  input {
    border: none;
    width: 100%;
  }

  input:focus {
    outline: none;
  }

  h3,
  p {
    color: var(--text-color);
  }

  form {
    .title {
      padding: 20px 0;
      border-bottom: 1px solid var(--border-color);

      h3 {
        font-size: 16px;
        line-height: 20px;
      }
    }

    .light-text {
      opacity: 0.5;
      font-size: 14px;
      line-height: 20px;
    }

    .subtitle {
      margin-bottom: 8.5px;
      font-size: 14px;
      line-height: 20px;
    }

    .section {
      padding-top: 28.5px;
      padding-bottom: 19.5px;
      border-bottom: 1px solid var(--border-color);

      .section-wrapper {
        width: 80%;
        display: flex;
        justify-content: space-between;

        @media screen and (max-width: 1000px) {
          width: 100%;
        }

        .right {
          width: 50%;

          .input {
            width: 100%;
            border: 1px solid var(--border-color);
            border-radius: 5px;
            padding: 11px 12px;
            display: flex;
            gap: 0px 10px;

            svg {
              font-size: 17px;
              color: black;
              opacity: 0.24;
            }
          }
        }
      }
    }

    .top {
      padding-top: 28.5px;
      padding-bottom: 12px;
      border-bottom: 1px solid var(--border-color);

      h3 {
        margin-bottom: 12px;
      }
    }

    .notifications {
      padding-bottom: 73.5px;

      .options {
        .notification-option-title {
          color: #707070;
          margin-top: 20.5px;
          margin-bottom: 16.5px;
        }

        .checkboxes {
          display: flex;
          justify-content: flex-start;
          grid-gap: 2rem 5rem;
          width: 85%;
          flex-wrap: wrap;

          .notif-box {
            display: flex;
            align-items: center;
            gap: 0px 8px;
            cursor: pointer;

            .checkbox {
              min-width: 20px;
              min-height: 20px;
              border: 1px solid $--gray;
              border-radius: 5px;

              .checked-item {
                color: var(--green);
                font-weight: 600;
                position: relative;
                top: 2.5px;
                left: 3px;
                font-size: 16px;
              }
            }

            p {
              font-size: 12px;
              line-height: 14px;
            }
          }
        }
      }
    }

    .global-alert {
      padding-top: 29px;
      padding-bottom: 70.5px;
      display: flex;
      justify-content: space-between;
      // width: 70%;
      width: 80%;

      @media screen and (max-width: 1000px) {
        width: 100%;
      }

      .left {
        max-width: 272px;
      }

      .right {
        display: flex;
        flex-direction: column;
        gap: 10.5px 0px;
        width: 50%;

        .alert {
          border: 1px solid var(--border-color);
          border-radius: 5px;
          // width: 316px;
          width: 100%;
          display: flex;
          gap: 0px 10px;
        }

        .body-text {
          width: 100%;
          display: flex;
          align-items: center;
        }

        .text {
          padding: 14px;
        }

        .date {
          padding: 13px 14px;

          .body-text .react-datepicker__close-icon::after {
            background-color: black;
            opacity: 0.24;
            content: ("X");
            color: var(--global-green);
            position: absolute;
            right: 0;
            top: calc(50% - 0.765rem);
          }

          .react-datepicker__week {
            // react-datepicker__day
            // react-datepicker__day--009

            // range-start selected
            // react-datepicker__day common classes (white boxes)
            // react-datepicker__day--010 common classes (white boxes)
            // react-datepicker__day--keyboard-selected
            // react-datepicker__day--range-start
            // react-datepicker__day--in-range

            // range day being selected
            // react-datepicker__day
            // react-datepicker__day--010
            // react-datepicker__day--keyboard-selected
            // react-datepicker__day--in-selecting-range
            // react-datepicker__day--selecting-range-start

            // day in range selected
            // react-datepicker__day
            // react-datepicker__day--011
            // react-datepicker__day--in-range

            // .react-datepicker__day--in-selecting-range {
            //   background-color: #8DDAB7;
            // }

            .react-datepicker__day--in-selecting-range,
            .react-datepicker__day--keyboard-selected {
              background-color: #8ddab7;
            }

            .react-datepicker__day--in-range {
              background-color: var(--green);
            }
          }
        }

        svg {
          color: black;
          opacity: 0.24;
          font-size: 18px;
        }
      }
    }

    button[type="submit"] {
      width: 90px;
      height: 28px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      border: none;
      background-color: var(--green);
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 1000px) {
  .title {
    padding: 20px 0px !important;
  }

  .section {
    .section-wrapper {
      flex-direction: column;
      gap: 12px 0px;
    }
  }

  .notifications {
    padding-bottom: 20px !important;
  }

  .checkboxes {
    flex-wrap: wrap;
    gap: 12px 40px;
    justify-content: flex-start;
  }

  .global-alert {
    flex-direction: column;
    gap: 12px 0px;
  }

  #settings form .notifications .options .checkboxes {
    grid-gap: 1rem 4rem;
    flex-direction: column;
  }
}
