#user-info,
#create-user-page {
  p {
    color: var(--text-color);
  }

  .not-saved {
    display: block !important;
  }

  .title {
    h3 {
      margin: 12px 0px;
      font-size: 16px;
    }
  }

  .section {
    padding-top: 20px;
    padding-bottom: 25px;
    border-bottom: 1px solid var(--border-color);

    p.light-text {
      opacity: 0.5;
    }

    .section-wrapper {
      display: flex;
      justify-content: space-between;
      width: 70%;

      @media screen and (max-width: 1000px) {
        width: 100%;
      }

      .left {
        display: flex;
        flex-direction: column;
        gap: 8px 0px;
        justify-content: center;
      }

      .right {
        width: 50%;

        .select-wrapper {
          min-width: 329px;

          @media screen and (max-width: 1000px) {
            min-width: 100%;
          }

          select {
            width: 100%;
            max-width: none !important;
            height: 45px;
            font-size: 13px;
          }

          select:focus {
            outline: none;
          }
        }

        .select-wrapper.error {
          .css-8oradf-control {
            border-color: red;
          }
        }

        .input {
          display: flex;
          border: 1px solid var(--border-color);
          padding: 11px;
          border-radius: 5px;

          .input-icon {
            margin-right: 13px;
            display: flex;
            align-items: center;

            svg {
              color: black;
              opacity: 0.24;
              font-size: 16px;
            }
          }

          input {
            border: none;
            width: 100%;
          }

          input:focus,
          select:focus {
            outline: none;
          }
        }

        .input.error {
          border-color: red;
        }
      }
    }
  }

  #title {
    h3 {
      margin-bottom: 12px;
      font-weight: 600;
      font-size: 16px;
    }
  }

  .buttons {
    margin-top: 1rem;
    display: flex;
    gap: 0px 22px;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 90px;
      height: 28px;
      border: none;
      border-radius: 5px;
      color: white;
    }

    #save-btn {
      background-color: var(--green);
    }

    #delete-btn {
      background-color: var(--red);
    }
  }
}

#access {
  margin-bottom: 1rem;
}

.autocomplete {
  display: flex;
  flex-direction: column;
  gap: 6px 0px;

  .selected-list {
    margin-top: 12px;
  }

  .fetched-clients:hover {
    background-color: var(--global-green);
  }

  .fetched-clients {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 6px 0px;
    font-size: 14px;
    border: 1px solid var(--border-color);
    border-radius: 5px;
    max-height: 200px;
    overflow-y: auto;

    P {
      padding: 8px 18px;
      cursor: pointer;
      width: 100%;
    }
    p:hover {
      color: white !important;
      background-color: var(--green);
    }
  }

  .available-clients {
    display: flex;
    gap: 6px;
    flex-wrap: wrap;
    max-width: 300px;
    max-height: 200px;
    overflow-y: auto;

    .client-box {
      border-radius: 5px;
      font-size: 10px;
      background-color: #707070;
      color: white;
      display: flex;
      justify-content: space-between;
      gap: 0px 12px;

      font-size: 1rem;
      padding: 0.5rem 1rem;
      background: var(--green);

      span {
        cursor: pointer;
      }
    }

    .delete-icon {
      font-size: 12px;
      color: white;
      cursor: pointer;
      min-width: 25px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }

  .input {
    cursor: pointer;

    svg {
      opacity: 0.5;
    }
  }

  .floating-list {
    background: white;
    border: 1px solid var(--border-color);
    border-radius: 0px 0px 5px 5px;
    max-width: 300px;
    max-height: 100px;
    overflow-y: scroll;

    .option-box {
      min-width: 100%;
      cursor: pointer;
      padding: 6px 8px;
      border: 1px solid white;
    }

    .option-box:hover {
      background-color: var(--global-green);
      border: 1px solid var(--green);
    }
  }
}

#user-info {
  margin-bottom: 2rem;
}
