/*Open SANS*/
.thin {
  font-weight: 100;
}

.extra-light {
  font-weight: 200;
}

.light {
  font-weight: 300;
}

.regular {
  font-weight: 400;
}

.medium {
  font-weight: 500;
}

.semi-bold {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}

.extra-bold {
  font-weight: 800;
}

.black {
  font-weight: 900;
}

/*Font Sizes*/
.f-10 {
  font-size: 10px;
}

.f-12 {
  font-size: 12px;
}

.f-14 {
  font-size: 14px;
}

.f-16 {
  font-size: 16px;
}

.f-18 {
  font-size: 18px;
}

.f-20 {
  font-size: 20px;
}

.f-22 {
  font-size: 22px;
}

.f-24 {
  font-size: 24px;
}

.f-26 {
  font-size: 26px;
}

.f-28 {
  font-size: 28px;
}

.f-30 {
  font-size: 30px;
}

.f-32 {
  font-size: 32px;
}

a {
  text-decoration: none;
}

ul {
  list-style-type: none;
}

/*Line Height*/

.lh-22 {
  line-height: 22px;
}

.lh-24 {
  line-height: 24px;
}

.lh-26 {
  line-height: 26px;
}

.lh-29 {
  line-height: 29px;
}

.lh-43 {
  line-height: 43px;
}

.lh-68 {
  line-height: 68px;
}

.lh-77 {
  line-height: 77px;
}

.lh-80 {
  line-height: 80px;
}

/*Letter Spacing*/

.ls-0_9px {
  letter-spacing: 0.9px;
}

.ls-1px {
  letter-spacing: 1px;
}

.ls-1_2px {
  letter-spacing: 1.2px;
}

.ls-3_6px {
  letter-spacing: 3.6px;
}

.ls-3_6px {
  letter-spacing: 3.6px;
}

.ls-3_6px {
  letter-spacing: 3.6px;
}

.ls-3_6px {
  letter-spacing: 3.6px;
}

.ls-3_6px {
  letter-spacing: 3.6px;
}

.ta-center {
  text-align: center;
}

.ta-left {
  text-align: left;
}

.ta-right {
  text-align: right;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize;
}