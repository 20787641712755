.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal {
    background: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 600px;
    width: 100%;
   
    .close-modal {
      position: absolute;
      top: 10px;
      right: 10px;
      background-color: var(--red);
      display: block;
      width: fit-content;
      margin-left: auto;
      border: none;
      color: white;
      border-radius: 5px;
      padding: 7px 15px;
      cursor: pointer;
    }
  }