.toggle-switch-container {
  text-align: center;
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  
  .toggle-switch {
    position: relative;
    width: 65px;
    display: inline-block;
    text-align: left;
    top: 0;
  }
  .checkbox {
    display: none;
    opacity: 0;
    visibility: hidden;
    position: absolute;
  }
  .label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid var(--border-color);
    border-radius: 20px;
  }
  .inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
  }
  .inner:before,
  .inner:after {
    float: left;
    width: 50%;
    height: 2.5rem;
    padding: 0;
    line-height: 2.5rem;
    color: #fff;
    font-weight: bold;
    box-sizing: border-box;
  }
  .inner:before {
    content: "YES";
    padding-left: 10px;
    background-color: var(--green);
    color: #fff;
  }
  .inner:after {
    content: "NO";
    padding-right: 10px;
    background-color: #bbb;
    color: #fff;
    text-align: right;
  }
  .switch {
    display: block;
    width: 20px;
    margin: 5px;
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 35px;
    border: 0 solid #bbb;
    border-radius: 20px;
    transition: all 0.3s ease-in 0s;
  }
  .checkbox:checked + .label .inner {
    margin-left: 0;
  }
  .checkbox:checked + .label .switch {
    right: 0px;
  }
}