@import './stylesheets/general/colors';
@import './stylesheets/general/fonts';
@import './stylesheets/general/general';
@import './stylesheets/general/variables';

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
}

html {
  font-size: 12px;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

:root {
  --global-green: #F6FFFB;
  --gray-border: #E9E9E9;
  --border-color: #E9E9E9;
  --green: #23B574;
  --red: #EB1B23;
  --orange: #FFA500;
  --text-color: #303030;
  --atlantic-blue: #197FB7;
  --light-red: #EB1B2321;
}

//Main page title-style

.error-message {
  color: var(--red) !important;
  margin-bottom: 6px;
}

.error {
  border: 1px solid var(--red) !important;

  svg {
    color: var(--red) !important;
  }
}

.success-message {
  color: var(--green);
}

.page-title {
  h3 {
    color: #303030;
    font-size: 1.5rem;
    font-weight: bold;
  }

  .breadcrumbs {
    margin-top: 1rem;

    svg {
      margin-left: 0.5rem;
      margin-right: 2px;
    }
  }

  @media(max-width: 1000px) {
    div {
      padding: 1rem 0;
    }

    h3 {
      font-size: 1.8rem;
    }

    .breadcrumbs {
      font-size: 1.4rem;
      margin-top: .5rem;

      svg {
        margin-bottom: -1px;
      }
    }
  }
}


#success-msg {
  transition: all .5s;
  background-color: var(--global-green);
  border: 1px solid var(--green);
  padding: .5rem 1rem;
  border-radius: 5px;
  width: 100%;
  margin-top: 12px;

  p {
    font-weight: 400;
    color: var(--green) !important;
  }
}

p,
td {
  font-size: 1.16rem;
  font-family: Arial, Helvetica, sans-serif;
}

a {
  text-decoration: none;
  color: inherit;
}

select,
option {

  cursor: pointer;
}

select {
  border: 1px solid var(--gray-border);
  border-radius: 5px;
  padding: 7px 14px;
  font-size: 12px;

  margin: 0rem 0.7rem;

  option {
    color: #303030;
    font-size: .9rem;
  }
}

select:focus {
  outline: none;
}

td:nth-child(1):hover {
  color: var(--green);
  transition: all .5s;
}

th,
.table-head {
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
}

.actions {
  svg {
    cursor: pointer;
  }
}

.email {
  color: var(--green);
  text-decoration: underline;
  opacity: 1;
  margin-left: .5rem;
  font-size: 1.16rem;
}

#error {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  text-align: center;
}


#main-container {
  display: flex;
}

.wrapper {
  width: calc(100% - 90px - 5rem);
}

.page-content {
  padding: 1.5rem 0rem 6.2rem 2.4rem;
}

@media screen and (max-width: 1000px) {
  html {
    font-size: 10px;
    overflow-x: visible;
  }

  #main-container {
    flex-direction: column-reverse;
    justify-content: flex-start;
    position: relative !important;
    min-height: 100vh;
  }

  .wrapper {
    width: 95%;
    margin: 0 auto;
  }

  .page-content {
    padding: 0;
  }

  .table,
  table {
    max-width: 100%;
    overflow: auto;
    display: block;
  }

  .pagination {
    margin-bottom: 12px;
  }

  .filters {
    justify-content: space-around !important;
  }
}

.pagination {
  button {
    cursor: pointer;
  }
}

button {
  cursor: pointer;
}

.button.danger {
  background: red;
  color: #fff;
}

.header {
  gap: 20px;

  .filters {
    display: flex;
    gap: 0px 16px;

  }
}

.filter-wrapper {
  margin: 1rem 0;
  display: flex;
}

.searchbar {
  position: relative;
  flex-grow: 1;
}

.searchbar input {
  border: 1px solid #E9E9E9;
  border-radius: 5px;
  padding: .75rem 1rem;
  width: 100%;
}

.searchbar svg {
  position: absolute;
  right: 1rem;
  top: .75rem;
  width: 1.4rem;
  height: 1.4rem;
}

#coming-soon {
  h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
    color: var(--green);
  }
}

.not-saved {
  width: 100%;
  padding: 14px;
  background-color: var(--light-red);
  border-radius: 5px;
  color: var(--text-color);
  margin-bottom: 12px;
}

.error-msg {
  color: red !important;
  margin-top: .5rem;
}

.flex {
  display: flex;
}

.flex.column {
  flex-direction: column;
}

.flex.jc-c {
  justify-content: center;
}

.flex.jc-fe {
  justify-content: flex-end;
}

.flex.jc-fs {
  justify-content: flex-start;
}

.flex.jc-sb {
  justify-content: space-between;
}

.flex.jc-se {
  justify-content: space-evenly;
}

.flex.jc-sa {
  justify-content: space-around;
}

.flex.ai-c {
  align-items: center;
}

.flex.ai-fe {
  align-items: flex-end;
}

.flex.ai-fs {
  align-items: flex-start;
}