$global-green: #F6FFFB;
$gray-border: #E9E9E9;
$border-color: #E9E9E9;
$green: #23B574;
$red: #EB1B23;
$global-red: #FCE1E3;
$l-black: #303030;

.black {
  color: black;
}

.l-black {
  color: $l-black;
}

.light-text {
  color: $l-black;
  opacity: .5;
}

.green {
  color: var(--green);
}

.light-green {
  color: var(--light-green);
}

.white {
  color: white;
}

.gray {
  color: var(--gray);
}

.white-bg {
  background: white;
}

.green-bg {
  background: var(--green);
}

.light-green-bg {
  background-color: var(--light-green);
}

.gray-bg {
  background: var(--gray);
}

.red {
  color: $red;
}

.red-bg {
  background: $red;
}

.no-bg {
  background: transparent;
}

.border-2-white {
  border: 2px solid white;
}

.border-1-green {
  border: 1px solid var(--green);
}