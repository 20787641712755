#login,
#forgot-pass {
  margin: auto;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  #main-logo {
    width: 129px;
    height: 129px;
  }

  .login-title {
    margin: 22px 0 0 0;
    font-size: 20px;

    @media screen and (max-width: 350px) {
      text-align: center;
    }
  }
  #wrong-msg {
    margin-top: 20px;
    margin-bottom: -21px;
    color: red;
  }
  #success-msg {
    margin-top: 20px;
    margin-bottom: -21px;
    color: var(--green);
    width: auto;
  }
  .inputs {
    width: 26rem;
    display: flex;
    gap: 0.75rem 0;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    margin-top: 41px;

    .input-container {
      display: flex;
      width: 100%;
      align-items: center;
      gap: 2rem;
      padding: 0.5rem 1.6rem;
      border: 1px solid var(--border-color);
      border-radius: 5px;
      .input-icon {
        display: flex;
        align-items: center;
        font-size: 20px;
        color: var(--text-color);
        opacity: 0.5;
      }
    }

    input:not([type="submit"]) {
      width: 100%;
      border: none;
      margin: 0;
    }

    textarea:focus,
    input:focus {
      outline: none;
    }

    img {
      width: 1.2rem;
    }
  }

  .button {
    display: inline-block;
    background: var(--green);
    color: white;
    padding: 0.8rem 3rem;
    border-radius: 5px;
    margin-top: 0.5rem;
    cursor: pointer;
    border: none;
  }

  input:not([type="submit"]) {
    padding: 7px 6px;
    margin-bottom: 1rem;
    font-size: 1.16rem;
    color: #303030;
  }

  .forgot-pass {
    font-size: 1rem;
    text-decoration: underline;
    color: #303030;
    margin-top: 5px;
    background: none;
  }
}

@media screen and (max-width: 1000px) {
  #login {
    width: 90%;
    margin: auto;
    .input-container {
      padding-right: 0;
    }
    input:not([type="submit"]) {
      width: 100%;
    }
    .inputs {
      max-width: 90%;
    }
  }
}

#forgot-pass {
  .input-container img {
    width: 1.4rem;
  }
}
