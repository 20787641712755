#delete-client {
  .delete-user-button{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90px;
    height: 28px;
    border: none;
    border-radius: 5px;
    color: white;
    background-color: var(--red);
  }
}

.delete-client-overlay{
  background: rgba(246, 255, 251, 0.5) !important;
  .react-confirm-alert-body{
    text-align: center;
    h1{
      color: var(--text-color);
      margin-bottom: 12px;
    }

    .react-confirm-alert-button-group{
      justify-content: center;
      button[label="No"]{
        background-color: var(--green);
      }
      button[label="Yes"]{
        background-color: var(--red);
      }
    }
  }
}