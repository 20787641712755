.individual.warehouse {
  width: 100%;
  button.delete-warehouse {
    width: fit-content;
    margin-left: auto;
  }
  .individual-header {
    height: 3px;
    color: var(--text-color);
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 16px;
  }

  .change-contacts {
    display: flex;
    gap: 0px 17px;
    align-items: flex-end;
    .inputs {
      display: flex;
      width: 100%;
      gap: 0px 35px;
    }
    .buttons {
      display: flex;
      gap: 0px 18px;
      button {
        width: 47px;
        height: 47px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: white;
        border: 1px solid var(--text-color);
        border-radius: 5px;
      }
    }
  }
  .adress {
    .right {
      display: flex;
      gap: 0px 24px;
    }
  }
  .render-emails {
    flex-direction: column;
  }
  .input-section {
    display: flex;
    gap: 0px 24px;
    width: 100%;
    margin-bottom: 12px;
    .input-container,
    .left,
    .right {
      width: 100%;
    }
    .input-container {
      margin-top: 6px;
      .input-label {
        font-size: 14px;
        line-height: 20px;
        color: var(--text-color);
        margin-bottom: 12.5px;
        display: block;
      }
      select {
        padding: 0 !important;
        border: none;
        max-width: 111px!important;
      }
      .input {
        display: flex;
        gap: 0px 13px;
        border-radius: 5px;
        border: 1px solid var(--border-color);
        padding: 14.5px 17px;
        max-height: 49px;

        .input-icon {
          color: #303c42;
          opacity: 0.24;
          svg{
            font-size: 18px;
          }
        }

        input {
          border: none;
          width: 100%;
        }

        input:focus,
        select:focus {
          border: none;
          outline: none;
        }
      }
    }
  }
}
