#home {
  .boxes {
    margin-bottom: 4rem;
  }
  .box {
    max-height: 87px;
    background: var(--global-green);
    border: 1px solid var(--border-color);
    display: flex;
    align-items: center;
    padding: 17px 24px 17px 29px;
    margin-bottom: 0;
    border-radius: 5px;
    transition: 0.25s;

    .box-numb p {
      font-size: 4rem;
      line-height: 55px;
      color: var(--green);
      font-weight: bold;
      transition: 0.25s;
    }

    .box-text {
      color: #303030;
      font-size: 1.16rem;
      margin-left: 1.6rem;
      transition: 0.25s;
    }
  }
  .box:hover {
    border: 1px solid var(--green);
  }

  .loading-box {
    background: linear-gradient(-45deg, #efefef, #ffffff, #efefef, #ffffff);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
    border-radius: 5px;

    .box-text,
    .box-numb p {
      color: transparent;
    }
  }
}

a:has(.box-home) {
  @media screen and (max-width: 370px) {
    width: 100% !important;
  }
}

.loading-box {
  background: linear-gradient(-45deg, #efefef, #ffffff, #efefef, #ffffff);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  border-radius: 5px;

  .box-text,
  .box-numb p {
    color: transparent;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.box:hover {
  border: 1px solid var(--green);
  cursor: pointer;
}

@media screen and (max-width: 1000px) {
  .box {
    flex-direction: column;
    max-height: none;
    padding: 0 0.5rem;
    min-width: 7rem;
    max-width: 50px !important;
  }
}
