.global-alert-container {
  background: rgba(235, 27, 35, 0.1294117647);
  padding: 1.2rem 2rem 1.2rem 1.2rem;
  border-radius: 5px;
  margin-bottom: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .global-alert-close {
    svg {
      font-size: 2.5rem;
    }
  }
}
