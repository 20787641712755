.navigation-arrows {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  align-items: center;

  .arrow-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    padding: 5px 10px;
    width: 150px;
    background-color: var(--green);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;

    .arrow-icon {
      width: 14px;
      height: 14px;
      fill: white;
      stroke: white;
      stroke-width: 1.5;
    }

    .arrow-text {
      font-size: 1.045rem;
      color: white;
      white-space: nowrap;
    }

    &:disabled {
      background-color: grey;
      color: black;
      cursor: not-allowed;
    }
  }
}

#grey-line {
  border-top: 1px solid #E9E9E9;
  width: calc(100vw - 8.4rem);
}

#shipment-info {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .left-side {
    width: 55%;
    align-items: center;

    .form-process {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      width: 100%;

      .form-step {
        width: 100%;
        display: flex;

        .form-body {
          flex-grow: 1;
          border-bottom: 1px solid var(--border-color);
          margin-left: 1.25rem;
          width: calc(100% - 4rem);

          .form-title {
            margin-bottom: 1rem;
            margin-top: 0.75rem;
            width: 100%;
            display: flex;
            align-items: center;

            cursor: pointer;

            .arrow {
              width: 5px;
              height: 5px;
              margin-left: auto;
              margin-right: 1rem;
            }
          }

          .form-content {
            display: none;

            .step-2_title {
              color: #707070;
              font-size: 12px;
              line-height: 14px;
              margin-bottom: 14.5px;
              margin-top: 14.5px;
            }

            .check-list {
              display: grid;
              gap: 1rem 5%;
              grid-template-columns: auto auto auto auto;

              @media screen and (max-width: 450px) {
                grid-template-columns: auto auto;
              }

              @media screen and (min-width: 451px) and (max-width: 1000px) {
                grid-template-columns: auto auto auto;
              }

              @media screen and (min-width: 1981px) {
                grid-template-columns: auto auto auto auto auto auto;
              }

              .check-box {
                display: flex;
                align-items: center;
                gap: 0px 8px;

                .box-dismiss {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  cursor: pointer;

                  svg {
                    font-size: 1.2rem;
                  }
                }

                .box {
                  width: 20px;
                  height: 20px;
                  border-radius: 5px;
                  border: 1px solid #707070;

                  .checked-item {
                    color: var(--green);
                    font-weight: 600;
                    position: relative;
                    top: 2.5px;
                    left: 3px;
                    font-size: 16px;
                  }
                }

                p {
                  font-size: 12px;
                  line-height: 14px;
                }
              }
            }

            .exams {
              p.exams-title {
                font-size: 12px;
                color: #707070;
                margin-bottom: 16.5px;
                margin-top: 1rem;
              }

              .check-list {
                flex-wrap: wrap;
                justify-content: flex-start;
                gap: 12px 47px;
              }
            }

            .docs_download {
              margin-bottom: 28.5px;

              .all-docs {
                display: flex;
                flex-wrap: wrap;
                gap: 12px 49px;
                max-height: 400px;
                overflow: auto;

                .doc-container {
                  display: flex;
                  align-items: center;
                  gap: 0px 12px;
                  cursor: pointer;

                  .icon-container {
                    background-color: var(--global-green);
                    border: 1px solid var(--border-color);
                    min-width: 68px;
                    height: 70px;
                    border-radius: 5px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .green svg {
                      color: var(--green);
                    }

                    svg {
                      color: #c7c7c7;
                      font-size: 51px;
                    }
                  }

                  .document-info {
                    color: #707070;
                    display: flex;
                    flex-direction: column;
                    gap: 10px 0px;

                    .file-name,
                    .file-date {
                      font-size: 12px;
                    }
                  }
                }
              }
            }

            .approve-docs {
              margin-bottom: 20px;
            }

            .approved-box {
              color: var(--green);
              font-weight: 600;
              position: relative;
              top: -2px;
              left: 3px;
              font-size: 16px;

              @media screen and (max-width: 1000px) {
                left: -1px;
              }
            }

            .require-docs {
              .top {
                margin-bottom: 18px;
              }

              .check-box {
                display: flex;
                align-items: center;
                gap: 0px 8px;

                .box {
                  width: 20px;
                  height: 20px;
                  border-radius: 5px;
                  border: 1px solid #707070;

                  .checked-item {
                    color: var(--green);
                    font-weight: 600;
                    position: relative;
                    top: 2.5px;
                    left: 3px;
                    font-size: 16px;
                  }
                }

                p {
                  font-size: 12px;
                  line-height: 14px;
                }
              }

              .file-description {
                margin-bottom: 29px;
              }

              .req-input {
                width: 100%;
                display: flex;
                gap: 0px 12px;

                .input {
                  width: 486px;
                  border: 1px solid var(--border-color);
                  border-radius: 5px;
                  padding: 13px 17px 15px 17px;
                  position: relative;

                  .dismiss-discrepancy {
                    position: absolute;
                    right: 5px;
                    top: 20%;
                    cursor: pointer;

                    svg {
                      font-size: 2.1rem;
                    }
                  }

                  input {
                    border: none;
                    font-size: 14px;
                    color: #707070;
                    width: 100%;
                  }

                  input:focus {
                    outline: none;
                  }

                  &:has(.dismiss-discrepancy) {
                    padding: 13px 35px 15px 17px;
                  }
                }

                .button {
                  button {
                    padding: 14px 26px;
                    background-color: var(--green);
                    border: none;
                    border-radius: 5px;
                    color: white;
                    font-size: 12px;
                  }
                }
              }
            }

            // DropZone Styles Addition
            .dropzone {
              padding: 100px;
              background-color: var(--global-green);
              border: 1px dashed var(--green);
              border-radius: 14px;
              text-align: center;

              &.active {
                background-color: var(--green-light);
              }

              p {
                font-size: 14px;
                color: black;
              }
            }
          }
        }

        .circle-completed {
          min-width: 3rem;
          height: 3rem;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1.16rem;

          svg {
            font-size: 20px;
          }
        }
      }

      margin-bottom: 4rem;
    }

    .update-order {
      .empty {
        text-align: center;
        margin: 2rem 0;
      }
    }

    .update-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid var(--border-color);
      padding-bottom: 1rem;

      .title {
        font-size: 1.2rem;
      }

      button {
        background-color: var(--green);
        color: white;
        padding: 5px 20px;
        border: none;
        border-radius: 5px;
      }

      .ignore-shipment-toggle {
        display: flex;
        align-items: center;
        margin-top: 1rem;
        cursor: pointer;

        label {
          display: flex;
          align-items: center;
          cursor: pointer;

          input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
          }

          .checkbox {
            width: 20px;
            height: 20px;
            display: inline-block;
            border: 1px solid black;
            border-radius: 5px;
            margin-right: 10px;
            position: relative;
            cursor: pointer;

            span {
              opacity: 0;
            }

            svg {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 1rem;
            }
          }

          input:checked+.checkbox {
            background: var(--green);
            border: none;

            span {
              opacity: 1;
            }
          }

          .checkbox-label {
            font-size: 1rem;
            color: #303030;
          }
        }
      }
    }

    .delivery-order-container {
      margin-top: 12px;

      button {
        cursor: pointer;
      }

      label p {
        margin-bottom: 0.3rem;
      }

      .tab-header {
        display: flex;
        justify-content: space-between;
        cursor: pointer;
      }

      .title {
        font-weight: bolder;
      }

      .delivery-order_form {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
        margin-top: 12px;

        .input-box {
          width: 49%;

          select {
            margin-top: 6px;
            margin-left: 0;
            width: 100%;
          }

          select:focus {
            outline: none;
          }
        }

        .input-box.error {
          border: none !important;

          .css-13cymwt-control {
            border-color: red;
          }
        }

        .reference_number {
          display: flex;
          flex-direction: column;
        }

        input[type="text"].text-input {
          width: 100%;
          margin-top: auto;
          height: 35px;
          border: 1px solid var(--border-color);
          padding: 0 6px;
          border-radius: 5px;
        }

        .delivery-order_note {
          display: flex;
          flex-direction: column;
          gap: 6px;
          width: 100%;

          textarea {
            border: 1px solid var(--border-color);
            border-radius: 5px;
            min-height: 50px;
            padding: 6px;
          }
        }

        .buttons {
          display: flex;
          gap: 12px;
          width: fit-content;
          margin-left: auto;

          button {
            padding: 6px 24px;
            border: none;
            color: white;
            border-radius: 5px;
          }

          .delete {
            background-color: var(--red);
          }

          .submit {
            background-color: var(--green);
          }
        }
      }
    }
  }

  .set_status {
    margin-top: 1rem;

    select {
      margin-top: 0.5rem;
      margin-bottom: 1rem;
      max-width: none !important;
      margin: 0.5rem 0 1rem;
    }
  }

  .notes {
    margin-top: 2rem;

    .title {
      font-size: 1.16rem;
    }

    .light-subtitle {
      font-size: 1rem;
      color: #303030;
      opacity: 0.5;
      margin-top: 0.5rem;
      margin-bottom: 1rem;
    }

    .by-user {
      width: 100%;
      background-color: var(--global-green);
      padding: 13px 20px;
      border: 1px solid var(--border-color);
      border-radius: 5px;
      margin-bottom: 12px;
      display: flex;
      justify-content: space-between;

      p {
        color: var(--text-color);
        word-break: break-word;
      }

      .date {
        font-size: 10px;
        color: var(--text-color);
        opacity: 0.5;

        position: relative;
        top: 14px;
        left: 13px;
      }
    }

    .note {
      textarea {
        background: white;
        border: 1px solid var(--border-color);
        border-radius: 5px;
        display: block;
        width: 100%;
        max-width: 100%;
        height: 42px;
        height: 100px;
        padding: 13px 20px;
        color: black;
        margin-bottom: 14px;
      }

      textarea:focus {
        outline: none;
      }

      .success-msg {
        color: var(--green);
        font-size: 1rem;
      }
    }

    button {
      display: block;
      width: fit-content;
      background-color: var(--green);
      font-size: 12px;
      padding: 7px 25px;
      border: none;
      color: white;
      border-radius: 5px;
    }

    .note-by-admin {
      background: var(--global-green);
      border: 1px solid var(--border-color);
      border-radius: 5px;
      display: block;
      width: 100%;
      padding: 13px 20px;
      color: black;
      margin-top: 14px;
    }
  }
}

.right-side {
  width: 40%;
  position: relative;

  .checkbox {
    margin-top: 12px;
    display: flex;
    align-items: center;
    gap: 0px 6px;

    .box {
      width: 20px;
      height: 20px;
      border-radius: 5px;
      border: 1px solid #707070;

      p {
        position: relative;
        left: 3px;
        top: -2px;
        color: var(--green);
        font-size: 16px;
        font-weight: 600;
      }
    }
  }

  .ignore-shipment-toggle {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    cursor: pointer;

    label {
      display: flex;
      align-items: center;
      cursor: pointer;

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
      }

      .checkbox {
        width: 20px;
        height: 20px;
        display: inline-block;
        border: 1px solid black;
        border-radius: 5px;
        margin-right: 10px;
        position: relative;
        cursor: pointer;

        span {
          opacity: 0;
        }

        svg {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 1rem;
        }
      }

      input:checked+.checkbox {
        background: var(--green);
        border: none;

        span {
          opacity: 1;
        }
      }

      .checkbox-label {
        font-size: 1rem;
        color: #303030;
        line-height: 1;
        /* Ensures the text aligns vertically within its container */
        display: flex;
        /* Ensures it aligns properly with the checkbox */
        align-items: center;
        margin-top: 1rem;
        /* Centers it vertically with the checkbox */
      }
    }
  }
}

.shipment-details {
  padding: 2rem 1.16rem 1.5rem 2rem;
  background: var(--global-green);
  border: 1px solid var(--border-color);
  border-radius: 5px;

  .title {
    font-size: 1.16rem;
    color: #303030;
    margin-bottom: 2.75rem;
  }

  .info-box {
    display: flex;
    border-bottom: 1px solid var(--border-color);
    padding: 0.65rem 0;

    .info-box-type {
      font-weight: bold;
      font-size: 1.16rem;
      width: 193px;
    }

    .info-box-value {
      color: #303030;
      font-size: 1.16rem;
      align-items: left;
      max-width: 50%;
      text-transform: uppercase;
    }

    .ref_no {
      button {
        background: transparent;
        border: none;
      }

      svg {
        color: gray;
        position: relative;
      }

      svg.green {
        color: var(--green);
      }
    }

    .ref_no input {
      background-color: transparent;
      border: none;
      font-size: 1.16rem;
      opacity: 1;
      color: black !important;
    }

    .ref_no input:focus {
      outline: none;
    }
  }

  #info-box-12 {
    border-bottom: none;
  }
}

.save-btn_container {
  display: flex;
  flex-direction: column;
  gap: 12px;

  #success-msg {
    margin-top: 0px;
  }
}

.files {
  display: flex;
  align-items: center;
  margin-top: 3rem;
  gap: 0.616rem;

  a.no-link {
    cursor: default;
    pointer-events: none;
  }

  .file.active-link {

    svg,
    p {
      color: var(--green);
      opacity: 1;
    }
  }

  .file {
    width: 9.75rem;
    height: 11rem;
    text-align: center;
    border: 1px solid var(--border-color);
    border-radius: 5px;
    padding: 1.6rem 1.83rem;

    p {
      color: #303030;
      opacity: 0.75;
    }

    svg {
      font-size: 3.3rem;
      color: #707070;
      opacity: 0.5;
      margin-bottom: 1.3rem;
    }

    .icon.green svg {
      opacity: 1;

      path {
        color: var(--green);
      }
    }
  }
}

.supplementary-file-section {
  .supplementary-files-container {
    flex-wrap: wrap;
    margin-top: 12px;

    .file-card {
      position: relative;

      .supplementary-x-container {
        width: 20px;
        height: 20px;
        border-radius: 50px;
        display: flex;
        position: absolute;
        top: -7px;
        background: #707070;
        align-items: center;
        justify-content: center;
        right: -5px;

        .supplementary-x {
          margin: 0 !important;
        }
      }

      .file-card-name {
        overflow: hidden;
      }
    }
  }

  .supplementary-files-title {
    margin-top: 12px;
  }
}

@media screen and (max-width: 1000px) {
  #shipment-info {
    flex-direction: column;
    margin-bottom: 4rem;

    .left-side,
    .right-side {
      width: 100%;
    }

    .form-content {
      padding-right: 3rem;
    }

    .check-box .box {
      min-width: 0;
    }

    .set_status {
      margin: 2rem 0;
    }
  }
}

.page-content.loading {
  background: linear-gradient(-45deg, #efefef, #ffffff, #efefef, #ffffff);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  border-radius: 5px;
  min-height: calc(100vh - 200px);
  margin-top: 1rem;
  margin-left: 1rem;
  width: 100%;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}